.center-cropped img {
  background-size: cover;
  background-position: center center;
  height: 300px !important;
}

.featured-homes {
  padding: 1em;
}

#mainHeader {
  background: #f6f9fc;
  padding-top: 1em;
  padding-bottom: 2em;
}

#mainApp {
  margin-top: 238px;
}

.formTitle {
  color: #3c424f !important;
  margin-bottom: 0.5em;
  font-size: 1.5em !important;
}
p{
  color: #3c424f;
}
body{
  background-color: #f6f9fc !important;
}
.white{
  color: white !important;
}
.center{
  text-align: center;
}
.float-left{
  float: left;
}
.menu .item{
  font-weight: bold !important;
}
.hide-mobile{
  display: none !important;
}
.inline-block{
  display: inline-block;
}
.float-right{
  float: right;
}
.margin-mobile{
  margin-top: .5em;
  margin-bottom: .5em;
}
.section1Content{
  height: 350px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url('/assets/mobileheader.jpg');
}
.section1HeaderContainer{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.section3{
  background: #C0E5C9; 
  padding-top: 2em;
  padding-bottom: 2.5em;
  margin-top: -3px;
}
h1{
  color: #3c424f !important;
}
h2{
  color: #3c424f !important;
}
h3{
  color: #3c424f !important;
}
input{
  margin-bottom: 1em !important;
}
.logo{
  display: block !important;
  height: 80px;
  margin: -5px auto;
}
.header-container{
  margin-bottom: -1em;
}
.headshot{
  height: 100px;
}
.contact-info-container {
  display: inline-block;
  margin-left: .5em;
  height: 100px;
}
.contact-info {
  color: #3c424f;
  display: block;
  text-align: left;
  margin-bottom: .5em;
}
.contact-info-footer {
  text-align: center;
  margin: auto 1em;
  vertical-align: middle;
  font-size: 1em;
}
.navigation {
  border-radius: 0 !important;
}
.slide{
  background: black;
}
.slick-dots li button:before {
  font-size: 18px !important;
  color: white !important;
  margin-top: 1em;
}
@media only screen and (min-width: 768px) {
  .featured-homes {
    padding: 3em;
  }

  #mainHeader {
    padding-bottom: 1em;
  }

  #mainApp {
    margin-top: 177px;
  }

  .header-container{
    float: right;
    margin-bottom: 0;
  }
  .headshot{
    height: 100px;
  }
  .contact-info {
    text-align: left;
    font-size: 1.1em;
    margin-bottom: 1em;
  }
  .hide-mobile{
    display: inline-block !important;
  }
  .logo{
    display: inline !important; 
    min-height: 100px;
    margin: 0 auto;
  }
  h1{
    font-size: 2.85em !important;
    color: #3c424f !important;
  }
  h3{
    font-size: 1.375em !important;
    color: #3c424f !important;
    font-weight: 400 !important;
  }
  .section1Content {
    position: absolute;
    z-index: 1;
    height: 600px;
    left: 50%;
    transform: translateX(-50%);
    background-image: none;
  }
  .section3{
    padding-top: 5em;
    padding-bottom: 5.5em;
  }
  .hide-desktop{
    display: none !important;
  }
}